import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/logo192.png" className="App-logo" alt="NutriplannerAI logo" />
        <h1>Bienvenido a NutriplannerAI</h1>
      </header>

      <main>
        <section className="hero">
          <h2>Planifica tus comidas de forma inteligente y saludable</h2>
          <p>NutriplannerAI es tu asistente personal para una alimentación equilibrada y sin complicaciones</p>
          <button className="cta-button">¡Pruébalo gratis!</button>
        </section>

        <section className="features">
          <h3>Características principales</h3>
          <ul>
            <li>Planificación de menús semanal personalizada</li>
            <li>Sugerencias de recetas basadas en tus preferencias</li>
            <li>Lista de compras automática</li>
            <li>Control de calorías y nutrientes</li>
            <li>Integración con IA para optimizar tu dieta</li>
          </ul>
        </section>

        <section className="benefits">
          <h3>Beneficios</h3>
          <p>Con NutriplannerAI, podrás:</p>
          <ul>
            <li>Ahorrar tiempo en la planificación de comidas</li>
            <li>Reducir el desperdicio de alimentos</li>
            <li>Mejorar tus hábitos alimenticios</li>
            <li>Controlar mejor tu presupuesto para comida</li>
            <li>Descubrir nuevas recetas y sabores</li>
          </ul>
        </section>

        <section className="testimonials">
          <h3>Lo que dicen nuestros usuarios</h3>
          <blockquote>
            "NutriplannerAI ha cambiado mi forma de comer. Ahora como más variado y saludable sin esfuerzo."
            <footer>- María G.</footer>
          </blockquote>
        </section>
      </main>

      <footer className="App-footer">
        <p>© 2024 NutriplannerAI. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default App;